<template>
  <div>
    <b-card
      no-body
      class="p-1 mb-0"
    >
      <div class="title">
        Historial de camas en fuera de servicio
      </div>
      <b-table
        responsive
        :fields="fields"
        :items="records"
        show-empty
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Cargando datos...</strong>
          </div>
        </template>
        <template #empty>
          <h4 class="text-center py-2">
            No hay registros
          </h4>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
	BCard,
  BTable,
  BSpinner,
} from 'bootstrap-vue'

export default {
  inject: ['bedBlockingRepository'],
  components: {
    BCard,
    BTable,
    BSpinner,
  },
  data() {
    return {
      fields: [
				{
					key: 'location.name',
					label: 'Cama',
				},
				{
					key: 'observations',
					label: 'Observación',
				},
        {
          key: 'finished_date',
          label: 'Fecha de finalización',
        },
        {
          key: 'technical_observations',
          label: 'Observación técnica',
        },
			],
      isBusy: false,
      records: [],
    }
  },
  mounted() {
    const me = this
    me.loadBedBloking()
  },
  methods: {
    async loadBedBloking() {
      const me = this
      try {
        me.isBusy = true
        const { data } = await me.bedBlockingRepository.getAll()
        data.forEach(el => {
          if (el.finished_date) me.records.push(el)
        })
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBusy = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 18px;
    color: #636363;
    font-weight: bold;
    margin-bottom: 10px;
  }
</style>
